import {
  countryCodeToLocale,
  localeToCountryCode,
  localeWithCountryCode,
} from './localeUtils'
import { removeTrailingSlash, titleCase, validateUUID } from './stringUtils'
import type { AuthorData, Tag } from '../types/helperTypes'
import type { Article, Thing, Person, FAQPage } from 'schema-dts'
import {
  dateTimeIso,
  getISODuration,
  getLocaleDate,
  sumISODurations,
} from './datetimeUtils'
import { ContentTeaser } from '../types/basic'
import { generateMetaTitle } from '../plugins/seo/MetaTags'
import { languageIdentifierMap } from './index'

import type {
  RecipeIngredientBlockItem,
  RecipeIngredientItem,
  RecipeNutrientItem,
} from '../types/widgets/composites/recipeTypes'
import { definePublisher } from '../composables/definePublisher'

/**
 * Finds the page title in the content, and sets it.
 *
 * @param content
 */
export const scanForTitle = (content: any, widgetList: any) => {
  const { $seo } = useNuxtApp()
  $seo.setTitle(generateMetaTitle(content))
  return content
}

/**
 * Will look for data that can add meta tags, that are not page type specific,
 * and add them to the singleton seo plugin.
 *
 * @param content
 * @return void
 */
export function addGlobalMetaTags(content: any) {
  const { $seo } = useNuxtApp()

  $seo.addMetaTag('ogImage', findOgImage(content))
  $seo.addMetaTag('ogImageType', ogImageType)
  $seo.addMetaTag('ogImageWidth', ogImageWidth)
  $seo.addMetaTag('ogImageHeight', ogImageHeight)
  $seo.addMetaTag('ogImageSecureUrl', findOgImage(content))

  /*
  $seo.addMetaTag(
    `cXenseParse:${cxenseLangId}-reading-time`,
    content?.estimated_reading_time,
    true
  )
  $seo.addMetaTag('cXenseParse:bod-taxo-cat', findArticleSection(content), true)
  $seo.addMetaTag(
    `cXenseParse:${cxenseLangId}-taxo-cat-url`,
    findArticleCategory(content)?.canonical_url,
    true
  )
  $seo.addMetaTag(
    `cXenseParse:${cxenseLangId}-taxo-cat-top`,
    findArticleSection(content),
    true
  )
  $seo.addMetaTag('cXenseParse:bod-template', content?.template, true)
  $seo.addMetaTag(
    `cXenseParse:${cxenseLangId}-taxo-tag`,
    content?.tags?.data.map((tag: Tag) => tag.name),
    true
  )

   */

  return content
}

/**
 * Finds the teaser matching the provided type.
 * Will return null, if none is found.
 *
 * @param { ContentTeaser } teasers
 * @param { string } type
 * @return ContentTeaser | undefined
 */
export function getTeaserWithType(
  teasers: ContentTeaser[] | null,
  type: string
): ContentTeaser | undefined {
  return (teasers || [])
    .filter((teaser: ContentTeaser) => teaser?.type === type)
    .pop()
}

function findTeaserImage(content: any) {
  return getTeaserWithType(content?.teasers?.data || [], 'default')?.image?.url
}

const findArticleAuthor = (content: any) => [
  titleCase(content?.author?.name || ''),
  ...(content?.other_authors || []).map((author: AuthorData) =>
    titleCase(author.name || '')
  ),
]

const findArticleCategory = (content: any) => content?.category?.data

const findArticleSection = (content: any) => findArticleCategory(content)?.name

const findOgTitle = (content: any) =>
  getTeaserWithType(content?.teasers?.data || [], 'default')?.title

const findOgDescription = (content: any) =>
  getTeaserWithType(content?.teasers?.data || [], 'default')?.description

const findOgImage = (content: any) => {
  const teaserImage = getTeaserWithType(
    content?.teasers?.data || [],
    'facebook'
  )?.image?.url

  if (!teaserImage) return ''

  return `${teaserImage}?max-w=${ogImageWidth}&max-h=${ogImageHeight}&fit=crop`
}
const ogImageWidth = 1200

const ogImageHeight = 630

const ogImageType = 'image/jpeg'

const findOgType = (content: any) => content?.kind?.toLowerCase()

export function addSchemas(content: any) {
  const { $seo } = useNuxtApp()

  if (!$seo.getSearchAdded()) {
    $seo.addSchema(createSiteSearchSchema())
    $seo.toggleSearchAdded()
  }

  if (contentTypeIsArticle()) {
    $seo.addSchema(createArticleSchema(content))
    if (contentHasRecipe(content)) {
      addRecipeSchema(
        (content?.contents?.data || []).find(
          (item: any) => item?.type === 'recipe'
        )
      )
    }

    if (hasQBrickVideo(content)) {
      const qbrick = useContentStore().qBrickVideoData
      const qbrickContent = qbrick?.value || qbrick

      if (!validateUUID(qbrickContent?.id)) {
        throw Error('Invalid Qbrick UUID')
      }

      const thumbnailObject = qbrickContent.asset.resources.find(
        resource => resource.type === 'image'
      )

      const videoInfoObject = qbrickContent.asset.resources.find(
        resource => resource.type === 'video'
      )

      const videoDataObject = {
        '@type': 'VideoObject',
        thumbnailUrl: thumbnailObject?.renditions[0]?.links[0]?.href,
        name: qbrickContent?.metadata?.title,
        description: qbrickContent?.metadata?.description,
        uploadDate: qbrickContent?.created,
        contentUrl: videoInfoObject?.renditions[0]?.links[0]?.href,
      }

      if (videoInfoObject?.renditions[0]?.videos[0]?.duration) {
        videoDataObject['duration'] = getISODuration({
          s: videoInfoObject?.renditions[0]?.videos[0]?.duration,
        })
      }
      $seo.addSchema({
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: [videoDataObject],
      })
    }
  }

  if (content?.faq_meta?.length) {
    $seo.addSchema(createFAQSchema(content.faq_meta))
  }
  return content
}

function hasQBrickVideo(content: any): boolean {
  const contentStore = useContentStore()
  return !!contentStore.qBrickVideoData
}

function contentHasRecipe(content: any): boolean {
  return (content?.contents?.data || []).find(
    (item: any) => item?.type === 'recipe'
  )
}

function createFAQSchema(items: any[]): FAQPage {
  return {
    '@type': 'FAQPage',
    mainEntity: items.map(item => ({
      '@type': 'Question',
      name: item.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.answer,
      },
    })),
  }
}

function getRecipeNutrient(
  nutrients: RecipeNutrientItem[] | null,
  nutrientSearch: string,
  nutrientName: string
): null | object {
  const nutrient = nutrients?.find(
    (obj: RecipeNutrientItem) => obj.nutrient === nutrientSearch
  )
  return nutrient
    ? { [nutrientName]: `${nutrient.amount ?? 0} ${nutrient.unit}` }
    : null
}

function getSchemaPerson(person: AuthorData): Person {
  return {
    '@type': 'Person',
    name: person.name,
    url: person.url,
    jobTitle: person.title,
    description: person.biography,
    image: person.avatar?.url,
  }
}

async function addRecipeSchema(recipe: RecipeType) {
  const contentStore = useContentStore()
  const category = contentStore.content?.category
  const authors = []

  if (contentStore.content?.author) {
    authors.push(getSchemaPerson(contentStore.content.author))
  }

  if (contentStore.content?.other_authors) {
    contentStore.content.other_authors.map((author: AuthorData) =>
      authors.push(getSchemaPerson(author))
    )
  }

  useHead({
    script: {
      hid: 'recipe-schema',
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        '@context': 'https://schema.org',
        '@id': `https://${useRuntimeConfig().public.SITE_URL + useRoute().path}#recipe`,
        '@type': 'Recipe',
        name: recipe?.title,
        image: recipe?.image?.url,
        author: authors,
        datePublished: dateTimeIso(contentStore.content?.published_at),
        dateModified: dateTimeIso(contentStore.content?.updated_at),
        description: recipe?.description,
        prepTime: getISODuration({ m: `${recipe?.preparation_time_min}` }),
        cookTime: getISODuration({ m: `${recipe?.cooking_time_min}` }),
        totalTime: getISODuration({ m: `${recipe?.total_time_min}` }),
        recipeYield: recipe?.quantity ?? 0,
        recipeCategory: category?.data?.name,
        nutrition: {
          ...(getRecipeNutrient(recipe?.nutrient_items, 'Energy', 'calories') ||
            {}),
          ...(getRecipeNutrient(
            recipe?.nutrient_items,
            'Protein',
            'proteinContent'
          ) || {}),
          ...(getRecipeNutrient(recipe?.nutrient_items, 'Fat', 'fatContent') ||
            {}),
          ...(getRecipeNutrient(
            recipe?.nutrient_items,
            'Carbohydrate',
            'carbohydrateContent'
          ) || {}),
          ...(getRecipeNutrient(
            recipe?.nutrient_items,
            'Fiber',
            'fiberContent'
          ) || {}),
        },
        recipeIngredient: recipe?.ingredient_block_items
          ?.map((block: RecipeIngredientBlockItem) =>
            block.ingredient_items
              .map((item: RecipeIngredientItem) =>
                item.amount
                  ? `${item.amount} ${useTranslationStore().getTranslation('recipe.' + item.unit) || item.unit} ${item.ingredient}`
                  : null
              )
              .filter((val: string | null) => !!val)
          )
          .flat(),
        recipeInstructions: await getInstructionsSchemaItems(
          recipe?.instructions
        ),
        keywords: recipe?.recipe_tags,
      }),
    },
  })
}

/**
 * Checks if the current content type is an article.
 *
 * @return boolean
 */
function contentTypeIsArticle(): boolean {
  return useContentStore().pageType === 'content'
}

/**
 * Used to convert recipe instructions, into markdown elements,
 * and then generate and return the schema data, based of that.
 *
 * @param {String} instructions
 */
async function getInstructionsSchemaItems(instructions: string) {
  const sectionHeadlineTags = ['p', 'h1']
  const listTags = ['ul', 'ol']
  const items = (await parseMarkdown(instructions))?.body?.children
  const schema: { '@type': string; text?: any }[] = []

  items.forEach((item, idx) => {
    if (sectionHeadlineTags.includes(item.tag)) {
      schema.push({ '@type': 'HowToSection' })
    }

    if (listTags.includes(item.tag)) {
      item.children.forEach(child => {
        if (child.children[0].type === 'text') {
          schema.push({ '@type': 'HowToStep', text: child.children[0].value })
        }
      })
    }
  })

  return schema
}

function createSiteSearchSchema(): Thing {
  const content = useContentStore().content

  const jsonSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    '@graph': [
      {
        '@type': 'WebPage',
        '@id': `https://${useRuntimeConfig().public.SITE_URL + useRoute().path}#website`,
        inLanguage: localeWithCountryCode(content?.locale, true) || '',
        name: content?.title || findOgTitle(content),
        url: `https://${useRuntimeConfig().public.SITE_URL + useRoute().path}`,
        potentialAction: [
          {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: `https://${useRuntimeConfig().public.SITE_URL}/search?q={search_term_string}`,
            },
            "query-input": {
              '@type': 'PropertyValueSpecification',
              valueRequired: true,
              valueName: 'search_term_string',
            }
          },
        ],
      }
    ]
  }

  useHead({
    script: {
      hid: 'site-search-schema',
      type: 'application/ld+json',
      innerHTML: JSON.stringify(jsonSchema),
    },
  })
}

function createArticleSchema(content: any): Article {
  const fullUrl = `https://${useRuntimeConfig().public.SITE_URL + useRoute().path}`

  const { getInternalTags } = useContentStore()

  const hiddenTags = (getInternalTags() || []).map(
    (tag: { slug: string }) => tag.slug
  )

  const isAccessibleForFree = hiddenTags.includes('premium') ? false : true

  const articleSchema = defineArticle({
    '@id': fullUrl + '#article',
    '@type': defineArticleType(),
    mainEntityOfPage: removeTrailingSlash(
      `https://${useRuntimeConfig().public.SITE_URL + useRoute().path}`
    ),
    description: getTeaserWithType(content?.teasers?.data || [], 'seo')
      ?.description,
    inLanguage: localeWithCountryCode(content?.locale, true) || '',
    url: content?.canonical_url,
    headline:
      content?.title ||
      getTeaserWithType(content?.teasers?.data || [], 'seo')?.title,
    image: defineImage({
      url: findTeaserImage(content),
    }),
    name:
      content?.title ||
      getTeaserWithType(content?.teasers?.data || [], 'default')?.title,
    datePublished: dateTimeIso(content?.published_at),
    dateModified: dateTimeIso(content?.updated_at),
    author: [content?.author, ...(content?.other_authors || [])].map(
      (author: AuthorData) => getSchemaPerson(author)
    ),
    publisher: definePublisher(),
    isAccessibleForFree: isAccessibleForFree, // Add isAccessibleForFree schema markup
  })

  // Conditionally add hasPart schema markup if isAccessibleForFree is false
  if (!isAccessibleForFree) {
    articleSchema.hasPart = {
      '@type': 'WebPageElement',
      isAccessibleForFree: false,
      cssSelector: '.paywall',
    }
  }

  return articleSchema
}

/**
 * Checks if content type is a sub category of an article,
 * else the default Article value is returned
 *
 * @return string
 */
function defineArticleType(): string {
  return (
    {
      Blog: 'BlogPosting',
      News: 'NewsArticle',
    }[
      useContentStore()?.content?.is_news
        ? 'News'
        : useContentType().contentType.value
    ] || 'Article'
  )
}
